export const SET_VIEW_TYPE = "SET_VIEW_TYPE";

export const SET_PLATFORM = "SET_PLATFORM";
export const SET_OS_TYPE = "SET_OS_TYPE";
export const SET_SELECTED_CORE_BUSINESS = "SET_SELECTED_CORE_BUSINESS";
export const SET_SELECTED_SOLUTION_AREA = "SET_SELECTED_SOLUTION_AREA";
export const SET_SELECTED_ENV = "SET_SELECTED_ENV";
export const SET_SELECTED_SERVICE = "SET_SELECTED_SERVICE";

export const GET_CORE_BUSINESS_LIST = 'GET_CORE_BUSINESS_LIST';
export const GET_SOLUTION_AREA = 'GET_SOLUTION_AREA';
export const GET_SERVICES_LIST = "GET_SERVICES_LIST";
export const GET_ENV_LIST = "GET_ENV_LIST";

export const GET_SERVERS_LIST = "GET_SERVERS_LIST";
export const GET_SERVERS_LIST_BY_VERSION = "GET_SERVERS_LIST_BY_VERSION";
export const GET_SERVERS_LIST_BY_SERVICE = "GET_SERVERS_LIST_BY_SERVICE";
export const GET_OVERVIEW_METRICS = "GET_OVERVIEW_METRICS";
export const GET_HIERARCHICAL_DATA = "GET_HIERARCHICAL_DATA";
export const GET_TOP20_UNSUPPORTED = "GET_TOP20_UNSUPPORTED";
export const GET_SERVERS_LIST_BY_ENV = "GET_SERVERS_LIST_BY_ENV";
export const GET_PATCH_TIMELINE = "GET_PATCH_TIMELINE";
export const GET_SWARM_DATA = 'GET_SWARM_DATA';
export const GET_TREND_DATA = 'GET_TREND_DATA';

export const SET_FETCHING_FLAG = "SET_FETCHING_FLAG";
export const GET_FINOPS_DATA = "GET_FINOPS_DATA";
